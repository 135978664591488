import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Layout from "../../components/Layout";
import { ImageConstants } from "../../constants/Imagconstants";
import { homeSkillSlider } from "../../constants/swiperConstants";
import { Link } from "gatsby";
import { routes } from "../../constants/routes";

const DesignServices = () => {
  return (
    <Layout title={"Web design, branding services in Luxembourg | Razrtech"}>
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="subpageheader">
                  <h1>Design</h1>
                  <p>
                    Stunning design that fits your needs now and into the
                    future. Our team of design and UX experts have a proven
                    track record of creating functional, visually striking
                    businesses and platforms
                  </p>
                  <a
                    href="https://form.jotform.com/222352612041340"
                    target="_blank"
                    className="btn btn-primary mt-5"
                  >
                    Get Started
                  </a>
                  <div className="design-bg-1">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg1}
                      alt="design"
                    />
                  </div>
                  <div className="design-bg-2">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg2}
                      alt="design"
                    />
                  </div>
                  <div className="design-bg-3">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg3}
                      alt="design"
                    />
                  </div>
                  <div className="design-bg-4">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg4}
                      alt="design"
                    />
                  </div>
                  <div className="design-bg-5">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg5}
                      alt="design"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="desing-video">
                  {/* <img loading="lazy" src={ImageConstants.designVideo} alt="video" /> */}
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/iVQpUHfEunw?autoplay=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60">
                  <h2 className="commantitel">
                    We create world-class digital products, web design, and
                    branding.
                  </h2>
                </div>
                <div className="desing-box desing-box-yellow mobileflexreverse">
                  <div className="desing-text">
                    <h3>User Experience</h3>
                    <p>
                      We work with product and development teams within
                      established businesses, enterprise organizations and
                      funded start-ups to rapidly improve the UX and UI of their
                      digital products.
                    </p>
                    <Link className="btn btn-primary mt-3" to={routes.pricing}>
                      See Pricing
                    </Link>
                  </div>
                  <div className="img">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.userExperience}
                        alt="User Experience"
                      />
                    </figure>
                  </div>
                </div>
                <div className="desing-box desing-box-light-blue">
                  <div className="img">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.corporateDesign}
                        alt="User Experience"
                      />
                    </figure>
                  </div>
                  <div className="desing-text">
                    <h3>
                      Corporate <br /> Presentation
                    </h3>
                    <p>
                      Razr Studio is your one-stop destination for personalized
                      corporate presentation services. We have a team of
                      designers, brand strategists, writers, and marketers to
                      help you understand the target audience and storyboarding
                      your message.
                    </p>
                    <Link className="btn btn-primary mt-3" to={routes.pricing}>
                      See Pricing
                    </Link>
                  </div>
                </div>
                <div className="desing-box desing-box-robin mobileflexreverse">
                  <div className="desing-text">
                    <h3>Branding</h3>
                    <p>
                      Branding is your businesses’ identity. Many people think
                      branding is all about how something looks, but it extends
                      to each and every aspect of your company. It shapes the
                      way people perceive you, and influences whether or not
                      they will invest in you. Our Branding team will help you
                      with the effective branding that attracts your customers
                      and keeps them coming back for more.
                    </p>
                    <Link className="btn btn-primary mt-3" to={routes.pricing}>
                      See Pricing
                    </Link>
                  </div>
                  <div className="img">
                    <figure>
                      <img
                        loading="lazy"
                        src={ImageConstants.brandingIcon}
                        alt="branding"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="improving-box">
                  <h3>
                    Improving the UI and UX of a product increase customer
                    satisfaction and product value. Time and again market
                    leaders accelerate ahead of their competition by investing
                    in improving their web app, mobile apps and websites.
                  </h3>
                       <a className="btn btn-primary mt-3" href={routes.portfolio}>  View our work</a>
                 
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60">
                  <h2 className="commantitel">They trust us</h2>
                </div>
              </div>
            </div>
          </div>
          <Swiper className="homeskillslider" {...homeSkillSlider}>
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientCastrol}
                    alt="castrol"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientToi}
                    alt="toi"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientGoeres}
                    alt="goeres"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientFirstpost}
                    alt="firstpost"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img loading="lazy" src={ImageConstants.clientLt} alt="l-t" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientFlipkart}
                    alt="flipkart"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientLatestly}
                    alt="latestly"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientTeamup}
                    alt="teamup"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientIronman}
                    alt="ironman"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.fiveRing}
                    alt="5_Ring"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.twoImirates}
                    alt="2_Emirates"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.threeMgs}
                    alt="3_MG"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.sevenGMC}
                    alt="7_GMC"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.sevenChevrolet}
                    alt="7_Chevrolet"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.oneIsuzu}
                    alt="1_Isuzu"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.sixRoyalEnfield}
                    alt="6_Royal-Enfield"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.fourLoreal}
                    alt="4_Loreal"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img loading="lazy" src={ImageConstants.nineBp} alt="9_bp" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientVodafone}
                    alt="client-vodafone"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientGeMoney}
                    alt="client-ge-money"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientAudi}
                    alt="client-audi"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientArcelor}
                    alt="client-arcelor"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientTomorrowStreet}
                    alt="tomorrow-street"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientCna}
                    alt="client-cna"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientTechnoport}
                    alt="client-technoport"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientChronicle}
                    alt="client-chronicle"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientEy}
                    alt="client-ey"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientMpay}
                    alt="client-mpay"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <img
                    loading="lazy"
                    src={ImageConstants.clientWikiStage}
                    alt="client-WikiStage"
                  />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </section>
        <section>
          <div className="container">
            <div className="letstalksection">
              <div className="row">
                <div className="col-md-8">
                  <div className="text-center">
                    <h2 className="commantitel">Let’s talk about your needs</h2>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="text-center">
                    <a
                      href="https://form.jotform.com/222352612041340"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DesignServices;
